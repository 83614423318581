import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import cLoadingImage from '../../assets/img/preloader-black.gif';
import Header from './Header';
import NavItem from './NavItem';
import SideMenu from './SideMenu';
import Axios from '../../config/Axios';
import '../../assets/scss/variables.scss';
import './styles/font.scss';
import './styles/page.scss';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { signIn } from '../../config/Authentication';

class Page extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      nUserId: oLocalStorage.get('nUserId'),
      oUser: {
        user_cname: '',
        user_cavatar: null,
        customer: {
          cust_nquotaused: null,
          nDiskSpace: null
        }
      },
      bLoading: true,
      bIsMenuOpened: false,
      bIsTransitionEnd: false,
      bSyncLoading: true
    };
    this.bIsSetFooter = true;
  }

  componentDidMount() {
    this.fetchUser()
      .then(cToken => {
        if (!cToken) {
          window.location.href = '/';
        }
        const oToken = decodeToken(cToken);
        const { oUser } = oToken;
        oUser.oCustomerUser = oToken.oCustomerUser;
        oUser.cStorageToken = oToken.cStorageToken;
        signIn(oToken.cStorageToken, true, false)

        this.setState({
          bSyncLoading: false
        });

        this.setState({
          oUser,
          bLoading: false
        });
      })
      .catch(oError => { });
  }

  changeIsMenuOpened = () => {
    const { bIsMenuOpened } = this.state;

    if (bIsMenuOpened) {
      this.setState({ bIsMenuOpened: false, bIsTransitionEnd: false });
    } else {
      this.setState({ bIsMenuOpened: true });
    }
  };

  fetchUser = () => {
    const { nUserId } = this.state;

    return Axios.get(`/user-authenticate/${nUserId}`).then(oResponse => oResponse.data);
  };

  render() {
    const {
      history,
      cId,
      className,
      bCentered,
      children,
      rcmpBreadcrumb,
      cTitle,
      cImage,
      cDescription,
      rcmpCardHeader,
      cCurrentSideMenuOption,
      bIsUserConfigMenu,
      loading
    } = this.props;
    const { oUser, bLoading, bIsMenuOpened, bIsTransitionEnd, bSyncLoading } = this.state;
    let cClassName = className;

    if (bSyncLoading) {
      return <></>;
    }

    if (bCentered === 'true') {
      cClassName += ' centered';
    }

    if (bIsMenuOpened) {
      cClassName += ' menu-expanded';
    }

    if (bIsTransitionEnd) {
      cClassName += ' sidemenu-names';
    }


    // const renderNavItems = () => {
    //   const { history, oUser, cCurrentHeaderOption } = this.props;
    //   const { bHasMessages } = this.state;
    //   let cFantasyName = '';
    //   let rcmpNavConfig = null;
    //   let cFantasyNameClass = 'v2-navitem-company-name';
    //   if (oUser && oUser.customer) {
    //     cFantasyName = oUser.customer.cust_cfantasyname;
    //   }

    //   // configurations
    //   /* if (can('admin')) { */
    //   cFantasyNameClass = '';
    //   rcmpNavConfig = (
    //     <NavItem
    //       bShowItem={can('admin')}
    //       key="navitem-config"
    //       id="v2-navitem-config"
    //       cIcon={cCurrentHeaderOption === intl.get('configs') ? imgfConfigHover : imgfConfig}
    //       cText={intl.get('configs')}
    //       onClick={evtClick => {
    //         this.setState({
    //           bIsOpen: !this.state.bIsOpen
    //         });
    //       }}
    //     >
    //       <OutsideHandlerClick
    //         handleClickOutside={evtClickOutside => {
    //           this.setState({
    //             bIsOpen: false
    //           });
    //         }}
    //       >
    //         {this.renderConfigMenu()}
    //       </OutsideHandlerClick>
    //     </NavItem>
    //   );
    //   /* } */

    //   const rcmpNavNotification = (
    //     <NavItem
    //       key="navitem-notification"
    //       id="v2-navitem-notification"
    //       cIcon={cCurrentHeaderOption === intl.get('Notification.title') ? notificationImgHover : notificationImg}
    //       cText={intl.get('Notification.title')}
    //       cLink="/notification"
    //       bHasAlert={bHasMessages}
    //       onClick={evtClick => {
    //         evtClick.preventDefault();
    //         history.push('/notification');
    //       }}
    //     >
    //       <span />
    //     </NavItem>
    //   );
    //   const rcmpNavCompany = (
    //     <NavItem key="navitem-company" id="v2-navitem-company" cText={cFantasyName} className={cFantasyNameClass} />
    //   );

    //   return (
    //     <>
    //       {rcmpNavConfig}
    //       {rcmpNavNotification}
    //       {rcmpNavCompany}
    //     </>
    //   );
    // };

    //   // configurations
    //   /* if (can('admin')) { */
    //   cFantasyNameClass = '';
    //   rcmpNavConfig = (
    //     <NavItem
    //       bShowItem={can('admin')}
    //       key="navitem-config"
    //       id="v2-navitem-config"
    //       cIcon={cCurrentHeaderOption === intl.get('configs') ? imgfConfigHover : imgfConfig}
    //       cText={intl.get('configs')}
    //       onClick={evtClick => {
    //         this.setState({
    //           bIsOpen: !this.state.bIsOpen
    //         });
    //       }}
    //     >
    //       <OutsideHandlerClick
    //         handleClickOutside={evtClickOutside => {
    //           this.setState({
    //             bIsOpen: false
    //           });
    //         }}
    //       >
    //         {this.renderConfigMenu()}
    //       </OutsideHandlerClick>
    //     </NavItem>
    //   );
    //   /* } */

    //   const rcmpNavNotification = (
    //     <NavItem
    //       key="navitem-notification"
    //       id="v2-navitem-notification"
    //       cIcon={cCurrentHeaderOption === intl.get('Notification.title') ? notificationImgHover : notificationImg}
    //       cText={intl.get('Notification.title')}
    //       cLink="/notification"
    //       bHasAlert={bHasMessages}
    //       onClick={evtClick => {
    //         evtClick.preventDefault();
    //         history.push('/notification');
    //       }}
    //     >
    //       <span />
    //     </NavItem>
    //   );
    //   const rcmpNavCompany = (
    //     <NavItem key="navitem-company" id="v2-navitem-company" cText={cFantasyName} className={cFantasyNameClass} />
    //   );


    //   return (
    //     <>
    //       {rcmpNavConfig}
    //       {rcmpNavNotification}
    //       {rcmpNavCompany}
    //     </>
    //   );
    // };


    return (
      <>
        <div
          className={`v2-page ${cClassName}`}
          onTransitionEnd={() => {
            this.setState({
              bIsTransitionEnd: bIsMenuOpened
            });
          }}
        >
          <SideMenu
            cCurrentSideMenuOption={cCurrentSideMenuOption}
            bIsUserConfigMenu={bIsUserConfigMenu}
            oUser={oUser}
            bIsOpened={bIsMenuOpened}
            fnMenuOpenEvent={this.changeIsMenuOpened}
          />
          {bLoading || loading === 1 ? (
            <div className="centered-loading">
              <img alt={intl.get('loading')} src={cLoadingImage} />
            </div>
          ) : (
            <div className="v2-page-container">
              {/* <header className="v2-top-header">
                <div />
                <h2 className="v2-title-page">Home</h2>
                <div className="v2-header-menu">
                  <ul className="v2-header-navitems">{renderNavItems()}</ul>
                </div>
              </header> */} 
              <Header
                history={history}
                cCurrentHeaderOption={cCurrentSideMenuOption}
                rcmpBreadcrumb={rcmpBreadcrumb}
                cTitle={cTitle}
                cImage={cImage}
                cDescription={cDescription}
                oUser={oUser}
              />
              <div className="v2-page-content">
                <div className="card v2-page-card">
                  {rcmpCardHeader}
                  <div id={cId} className="card-body">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Page.defaultProps = {
  className: '',
  cId: '',
  bCentered: false,
  children: [],
  rcmpBreadcrumb: '',
  cTitle: '',
  cDescription: '',
  rcmpCardHeader: '',
  cCurrentSideMenuOption: '',
  bIsUserConfigMenu: false,
  loading: 1,
};

export default withRouter(Page);
