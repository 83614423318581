import React from "react";

export default function CloseIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.5644 13.957C16.0316 14.4242 16.0316 15.1823 15.5644 15.6496C15.0972 16.1168 14.3391 16.1168 13.8718 15.6496L7.97919 9.71303L2.04466 15.6476C1.57743 16.1148 0.819351 16.1148 0.35207 15.6476C-0.115212 15.1803 -0.115162 14.4223 0.35207 13.955L6.28859 8.02244L0.350424 2.04303C-0.116808 1.5758 -0.116808 0.817724 0.350424 0.350443C0.817656 -0.116839 1.57573 -0.116789 2.04301 0.350443L7.97919 6.33185L13.9137 0.39732C14.3809 -0.0699117 15.139 -0.0699117 15.6063 0.39732C16.0736 0.864552 16.0735 1.62263 15.6063 2.08991L9.66978 8.02244L15.5644 13.957Z" fill="white" />
    </svg>

  );
}
