/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Alert } from '../../components/Alert';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import Norm from '../norm/Norm';
import NormExport from '../norm/NormExport';
import NormDuplicate from '../norm/NormDuplicate';
import Axios from '../../config/Axios';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import AltCheckbox from '../../components/AltCheckbox';
import { can, hasModule, checkModule } from '../../config/Permissions';
import url from '../../config/Url';
import imgfNoPic from '../../assets/img/nopic.svg';
import ScrollBar from '../../components/ScrollBar';
import Filter from '../default-v2/Filter';
import CardHeader from '../default-v2/CardHeader';
import Masonry from 'react-masonry-component';
import './styles/qualitypanelindex.scss';
import { textSearchFormat } from '../norm/RequisiteIndex';
import qualityImg from '../../assets/img/quality.svg';
import Button from '../../components/Button';
import { oLocalStorage } from '../../config/EncodedLocalStorage';

class QualityPanelIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      normas: [],
      filterBy: 'name',
      bShowInactives: false,
      filtredNorms: [],
      aPages: [{ cName: 'QualityPanelIndex.titulo', cLink: undefined }],
      oLoadNorm: '',
      alert: null
    };

    this.gridRef = React.createRef();

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'quality-panel');

    Axios.get('/norm')
      .then(res => {
        this.setState(
          {
            normas: res.data.map(obj => ({
              ...obj,
              bisactive: Boolean(obj.norm_bisactive)
            })),
            loading: false
          },
          () => {
            this.applyFilter();
          }
        );
      })
      .catch(() => { });
  };

  filterNorms = () => {
    const { normas, bShowInactives, filtredNorms } = this.state;
    return (filtredNorms.length ? filtredNorms : normas).filter(norm =>
      bShowInactives === true ? norm.bisactive === true || norm.bisactive === false : norm.bisactive === true
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  handleChangeState = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  msgSucesso = cMsg => {
    this.hideAlert();
    this.componentDidMount();

    this.setState({
      rcmpAlertMsg: (
        <Alert type="success" isOpen onCloseAlert={() => { }}>
          {cMsg}
        </Alert>
      )
    });
  };

  filterByName = (norm, filterSearch) => {
    const filter = textSearchFormat(String(filterSearch));

    return (
      (norm.norm_cname && textSearchFormat(norm.norm_cname).includes(filter)) ||
      (norm.norm_cinitials && textSearchFormat(norm.norm_cinitials).includes(filter))
    );
  };

  filterByResponsible = (norm, filterSearch) => {
    return (
      norm.norm_responsibles.filter(
        responsible =>
          responsible.user?.user_cname &&
          textSearchFormat(responsible.user?.user_cname).includes(textSearchFormat(String(filterSearch)))
      ).length > 0
    );
  };

  filterByConsultant = (norm, filterSearch) => {
    return (
      norm.norm_consultants.filter(
        consultant =>
          consultant.consultant?.user?.user_cname &&
          textSearchFormat(consultant.consultant.user?.user_cname).includes(textSearchFormat(String(filterSearch)))
      ).length > 0
    );
  };

  checkAllFilters = (norm, filterSearch) => {
    return (
      this.filterByName(norm, filterSearch) ||
      this.filterByResponsible(norm, filterSearch) ||
      this.filterByConsultant(norm, filterSearch)
    );
  };

  applyFilter = () => {
    const { filterSearch, normas } = this.state;

    const aFiltered = normas.filter(norm => this.checkAllFilters(norm, filterSearch));

    this.setState({
      filtredNorms: aFiltered.length ? aFiltered : []
    });
  };

  handleChange = e => {
    const { normas } = this.state;
    this.setState(
      {
        filtredNorms: normas,
        [e.target.name]: e.target.value
      },
      () => {
        this.applyFilter();
      }
    );
  };

  handleChangeFilter = (e, oFilter) => {
    this.setState(
      {
        filterSearch: oFilter.cText
      },
      () => {
        this.applyFilter();
      }
    );
  };

  abrirSubmenu = e => {
    if (e.target.closest('div').getElementsByClassName('submenu')) {
      e.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: block';
    }
  };

  fecharSubmenu = (e, wrapperRef) => {
    const element = wrapperRef;
    if (element.getElementsByClassName('submenu')[0]) {
      element.getElementsByClassName('submenu')[0].style = 'display: none';
    }
  };

  renderFilter = () => {
    const { filtredNorms, filterSearch, filterBy, bShowInactives } = this.state;
    return (
      <OutsideHandlerClick handleClickOutside={this.fecharSubmenu}>
        <form id="filter-box-norms" className="form submenu">
          <label className=" colspan">
            <Input
              onChange={this.handleChangeFilter}
              type="text"
              id="filterSearch"
              placeholder={intl.get('QualityPanelIndex.filtrar_por')}
              name="filterSearch"
              maxLength="100"
              value={filterSearch || ''}
              required
            />
          </label>
          {filtredNorms.length === 0 && filterSearch && (
            <p className="empty-search">{intl.get('QualityPanelIndex.sem_resultados')}</p>
          )}
          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'name'}
              value="name"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('name')}
          </label>
          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'responsible'}
              value="responsible"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('responsible')}
          </label>
          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'consultant'}
              value="consultant"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('consultor')}
          </label>

          <AltCheckbox
            title={intl.get('QualityPanelIndex.visualizar_inativas')}
            label={intl.get('QualityPanelIndex.visualizar_inativas')}
            className="split1"
            checked={bShowInactives}
            name="bShowInactives"
            onChange={this.handleChangeState}
          />
        </form>
      </OutsideHandlerClick>
    );
  };

  openNormas = e => {
    e.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('Norm.nova_norma')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Norm
              onSuccess={msg => {
                this.msgSucesso(msg);
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  showDeleteNorma = (e, { norm_nid, norm_cinitials }) => {
    e.preventDefault();

    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('QualityPanelIndex.titulo_excluir_norma', { cNormInitials: norm_cinitials })}
          onConfirm={() => this.removerNorma(norm_nid, norm_cinitials)}
          onCancel={this.hideAlert}
        >
          {intl.get('QualityPanelIndex.excluir_norma')}
          <p> {intl.get('QualityPanelIndex.excluir_norma2')} </p>
          <p> {intl.get('QualityPanelIndex.excluir_norma3')} </p>
        </SweetAlert>
      )
    });
  };

  removerNorma = (nid, cname) => {
    const { clearAlert } = this.props;
    Axios.delete(`norm/${nid}`)
      .then(() => {
        this.setState(
          {
            alert: (
              <SweetAlert
                success
                title={`${intl.get('RequisiteIndex.norma')} ${cname} ${intl.get('removida')}`}
                onConfirm={() => {
                  this.hideAlert();
                  this.componentDidMount();
                }}
              >
                {intl.get('RequisiteIndex.norma_excluida')}
              </SweetAlert>
            )
          },
          () => {
            clearAlert();
          }
        );
      })
      .catch(error => {
        this.setState({
          alert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{error.aMsgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  changeState = (e, { norm_nid }) => {
    e.preventDefault();
    this.setState({
      oLoadNorm: norm_nid
    });

    Axios.get(`/norm/change-status/${norm_nid}`)
      .then(response => {
        this.setState(
          {
            oLoadNorm: ''
          },
          () => {
            this.msgSucesso(response.data);
          }
        );
        this.componentDidMount();
      })
      .catch(error => {
        this.setState({
          oLoadNorm: '',
          alert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{error.aMsgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  openNormaExport = (e, oNorma) => {
    e.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow max-height ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('QualityPanelIndex.exportar_norma')} {oNorma.cnome}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>

          <ScrollBar>
            <NormExport
              oNormExport={oNorma}
              closeModal={this.hideAlert}
              onSuccess={cMsg => {
                this.msgSucesso(cMsg);
              }}
            //closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  openNormaDuplicate = (evtClick, oNorma) => {
    evtClick.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow max-height ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('QualityPanelIndex.duplicar')} {oNorma.cnome}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>

          <ScrollBar>
            <NormDuplicate
              oNormDuplicate={oNorma}
              closeModal={this.hideAlert}
              onSuccess={cMsg => {
                this.msgSucesso(cMsg);
              }}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };
  openNormaEdit = (evtClick, oNorma) => {
    evtClick.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow max-height ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('QualityPanelIndex.editar')} {oNorma.norm_cinitials}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>

          <ScrollBar>
            <Norm
              oNorm={oNorma}
              onSuccess={cMsg => {
                this.msgSucesso(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  checkIfUserIsConsultant = norm => {
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return !!norm.norm_consultants?.some(
      consultant => consultant?.consultant?.user?.user_nid === nUserId
    );
  };

  checkIfUserIsResponsibleForRequisite = norm => {
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return !!norm.requisites?.some(requisite => requisite?.user?.user_nid === nUserId);
  };

  checkIfUserIsResponsible = norm => {
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return !!norm.norm_responsibles?.some(
      responsible => responsible.user.user_nid === nUserId
    );
  };

  checkRemovePermission = norm => {
    return this.checkIfUserIsResponsible(norm);
  };

  checkViewPermission = norm => {
    return (
      this.checkIfUserIsResponsible(norm) ||
      this.checkIfUserIsResponsibleForRequisite(norm) ||
      this.checkIfUserIsConsultant(norm)
    );
  };

  checkCreateEditPermission = () => {
    return can('admin', 'quality-panel') || can('create-edit-norm-qualitypanel', 'quality-panel');
  };

  checkUserPermission = () => {
    return can('admin', 'quality-panel') || can('access-qualitypanel', 'quality-panel');
  };

  canEditNorm = (oNorm) => {
    const cModule = 'quality-panel';
    return can('admin', cModule) || (
      can('create-edit-norm-qualitypanel', cModule) &&
      this.checkIfUserIsResponsible(oNorm)
    );
  }

  canExportNorm = (oNorm) => {
    if (!oNorm || !oNorm.norm_bisactive) return false;
    if (!['Consultoria', 'Certificadora'].includes(oLocalStorage.get('cTypeUser'))) return false;
    return this.canEditNorm(oNorm);
  }

  generateCardHeader = () => {
    const { bShowInactives } = this.state;
    const aButtons = [];

    const rcmpBtnNewStandard = (
      <Button
        onClick={event => {
          this.openNormas(event);
        }}
      >
        {intl.get('QualityPanelIndex.nova_norma')}
      </Button>
    );

    aButtons.push(this.checkCreateEditPermission() ? rcmpBtnNewStandard : []);

    const aCheckboxes = [];
    const rcmpInactiveSwitch = (
      <AltCheckbox
        key="checkbox-show-inactives"
        onChange={this.handleChangeState}
        title={intl.get('QualityPanelIndex.inativos')}
        label={intl.get('QualityPanelIndex.inativos')}
        name="bShowInactives"
        localeLabel="end"
        checked={bShowInactives}
      />
    );
    aCheckboxes.push(rcmpInactiveSwitch);

    const rcmpFilter = (
      <Filter aButtons={aButtons} fnOnType={this.handleChangeFilter} bIsOrderActive={false} aCheckboxes={aCheckboxes} bHasVideoTutorial={true}/>
    );

    const rcmpCardHeader = <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={false} />;
    return rcmpCardHeader;
  };

  render() {
    const { loading, aPages, alert, rcmpAlertMsg, oLoadNorm } = this.state;
    const { history } = this.props;
    const cModule = 'quality-panel';

    return (
      <Page
        loading={loading ? 1 : 0}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cImage={qualityImg}
        cTitle={intl.get('QualityPanelIndex.titulo')}
        rcmpCardHeader={this.checkUserPermission() ? this.generateCardHeader() : null}
        cCurrentSideMenuOption={intl.get('Nav.quality_panel')}
        className="v2-quality-panel-index"
      >
        {alert}
        {rcmpAlertMsg}

        {!this.checkUserPermission() && (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('sem_permissao')}
          </Alert>
        )}

        {this.checkUserPermission() && (
          <div className="container-cards">
            <Masonry className="grid" elementType="ul">
              {this.filterNorms().map(
                (norma, index) =>
                  hasModule(cModule) &&
                  (can('admin') || this.checkViewPermission(norma)) && (
                    <li
                      key={`norma-${index}`}
                      className={`item norm-active-${norma.bisactive} ${oLoadNorm === norma.norm_nid ? 'load' : ''}`}
                      role="presentation"
                      title={norma.cnome}
                      onKeyPress={() => history.push(`/requisite/${norma.norm_nid}`)}
                      onClick={() => history.push(`/requisite/${norma.norm_nid}`)}
                    >
                      <div className="content">
                        <div className="header">
                          <h2>{norma.norm_cinitials}</h2>
                          <h3>{' '}</h3>
                          {norma.norm_biscertified && (
                            <img
                              className="seloNormAudit"
                              src={norma.cSealUrl}
                              alt="selo"
                            />
                          )}
                          <h3>
                            {norma.norm_cname.length < 80
                              ? norma.norm_cname
                              : norma.norm_cname.substring(0, 80) + '...'}
                          </h3>

                          <div className="right-menu">
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                this.abrirSubmenu(e);
                              }}
                              onKeyPress={e => {
                                e.stopPropagation();
                                this.abrirSubmenu(e);
                              }}
                              role="button"
                              tabIndex="0"
                              title=""
                              className="btn-menu"
                            >
                              <span className="insideP" />
                            </span>
                            <OutsideHandlerClick handleClickOutside={this.fecharSubmenu}>
                              <ul className="submenu">
                                {!!norma.norm_bisactive && this.canEditNorm(norma) && (
                                  <li key={`norma-${index}-submenu-1`}>
                                    <a
                                      onClick={e => {
                                        e.stopPropagation();
                                        this.openNormaEdit(e, norma);
                                      }}
                                      href="#change"
                                    >
                                      {intl.get('editar', { tipo: '' })}
                                    </a>
                                  </li>
                                )}

                                {this.canEditNorm(norma) && (
                                    <li key={`norma-${index}-submenu-2`}>
                                      <a
                                        onClick={e => {
                                          e.stopPropagation();
                                          this.changeState(e, norma);
                                        }}
                                        href="#state-change"
                                      >
                                        {norma.bisactive ? intl.get('inativar') : intl.get('ativar')}
                                      </a>
                                    </li>
                                  )}

                                <li key={`norma-${index}-submenu-3`}>
                                  <a
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      history.push(`/norm/interaction/${norma.norm_nid}`);
                                    }}
                                    href="#state-change"
                                  >
                                    {intl.get('QualityPanelIndex.interacoes')}
                                  </a>
                                </li>
                                {this.canExportNorm(norma) && (
                                  <li key={`norma-${index}-submenu-4`}>
                                    <a
                                      onClick={evtClick => {
                                        evtClick.stopPropagation();
                                        this.openNormaExport(evtClick, norma);
                                      }}
                                      href="#change"
                                    >
                                      {intl.get('QualityPanelIndex.exportar')}
                                    </a>
                                  </li>
                                )}

                                {this.canExportNorm(norma) && (
                                  <li key={`norma-${index}-submenu-5`}>
                                    <a
                                      onClick={evtClick => {
                                        evtClick.stopPropagation();
                                        this.openNormaDuplicate(evtClick, norma);
                                      }}
                                      href="#change"
                                    >
                                      {intl.get('QualityPanelIndex.duplicar')}
                                    </a>
                                  </li>
                                )}
                                {!!norma.norm_bisactive && this.canEditNorm(norma) && (
                                  <li key={`norma-${index}-submenu-6`}>
                                    <a
                                      href="#remove"
                                      onClick={e => {
                                        e.stopPropagation();
                                        this.showDeleteNorma(e, norma);
                                      }}
                                      className="alt"
                                    >
                                      {intl.get('excluir', { tipo: '' })}
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </OutsideHandlerClick>
                          </div>
                        </div>
                        <div className="contentRole">
                          <div className="containerBar">
                            <div className="progressBar">
                              <span
                                style={{
                                  width: `${norma.finishedRequisites ? norma.norm_nprogress : 0}%`
                                }}
                                className="fill"
                              />
                            </div>

                            <span className="tooltipNorma">
                              <p>
                                {intl.get('RequisiteIndex.status_concluido')} : {norma.finishedRequisites}{' '}
                              </p>
                              <p>
                                {intl.get('RequisiteIndex.status_nao_aplica')} : {norma.notAppliedRequisites}{' '}
                              </p>
                              <p>
                                {intl.get('RequisiteIndex.status_pendente')} : {norma.pendingRequisites}{' '}
                              </p>
                            </span>

                            <span className="percent">{`${norma.finishedRequisites ? norma.norm_nprogress : 0}%`}</span>
                          </div>

                          <div className="users">
                            <ul>
                              {norma.norm_responsibles.map((usr, responsibleIndex) => (
                                <li key={`norma-${index}-responsible-${responsibleIndex}`}>
                                  <img
                                    src={
                                      usr.user && usr.user.user_cavatar
                                        ? usr.user.user_cavatar
                                        : imgfNoPic
                                    }
                                    alt={intl.get('usuario')}
                                  />
                                  <span>
                                    <p>{usr.user ? usr.user?.user_cname : ''}</p>
                                    <p>{intl.get('responsible')}</p>
                                  </span>
                                </li>
                              ))}
                            </ul>

                            <ul>
                              {norma.norm_consultants.map((usr, consultantIndex) => (
                                <li key={`norma-${index}-consultant-${consultantIndex}`}>
                                  <img
                                    src={
                                      usr?.consultant?.user && usr?.consultant?.user?.user_cavatar
                                        ? usr.consultant.user.user_cavatar
                                        : imgfNoPic
                                    }
                                    alt={intl.get('usuario')}
                                  />
                                  <span>
                                    <p>{usr?.consultant?.user ? usr?.consultant?.user?.user_cname : ''}</p>
                                    <p>{intl.get('consultor')}</p>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
              )}
            </Masonry>
          </div>
        )}
      </Page>
    );
  }
}

export default QualityPanelIndex;
