import React, { Component } from 'react';
import './styles/cardheader.scss';
import imgfSelectLeft from './img/select-left.svg';
import PlayVideoTutorialIcon from '../../assets/icons/PlayVideoTutorialIcon';

class CardHeader extends Component {
  renderButtons() {
    const { aButtons } = this.props;
    if (typeof aButtons === 'undefined') {
      return '';
    }

    const rcmpButtons = Object.keys(aButtons).map(cKey => aButtons[cKey]);
    return rcmpButtons;
  }

  render() {
    const { cHtml, rcmpFilter, bIsItToInsertGoBackBtn, fnGoBack, history, rcmpOptionMenu, windowHistory, bHasVideoTutorial = true } = this.props;

    return (
      <div className="card-header v2-page-cardheader">
        {bIsItToInsertGoBackBtn ? (
          <div
            className="v2-page-btn-goback"
            onClick={evtClick => {
              if (windowHistory) {
                window.history.back();
              }
              if (fnGoBack === null && history) {
                history.goBack();
                return;
              }
              if (fnGoBack !== null) {
                fnGoBack(evtClick);
                return;
              }
            }}
          >
            <img src={imgfSelectLeft} alt="" className="" />
          </div>
        ) : (
          ''
        )}
        
        {cHtml}
        {this.renderButtons()}
        {rcmpFilter}
        {rcmpOptionMenu}
      </div>
    );
  }
}

CardHeader.defaultProps = {
  bIsItToInsertGoBackBtn: true,
  fnGoBack: null,
  history: null,
  rcmpOptionMenu: null
};

export default CardHeader;
