import React from "react"
export default function PlayVideoTutorialIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.0002 22.6667C17.8912 22.6667 22.6668 17.891 22.6668 12C22.6668 6.10896 17.8912 1.33333 12.0002 1.33333C6.10912 1.33333 1.3335 6.10896 1.3335 12C1.3335 17.891 6.10912 22.6667 12.0002 22.6667Z" stroke="#6CB6E3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.3335 8.76302C9.3335 8.25391 9.3335 7.99936 9.43989 7.85725C9.5326 7.73341 9.67452 7.65593 9.82883 7.6449C10.0059 7.63226 10.22 7.76991 10.6483 8.04521L15.6836 11.2822C16.0552 11.5211 16.241 11.6405 16.3052 11.7924C16.3612 11.9251 16.3612 12.0749 16.3052 12.2076C16.241 12.3595 16.0552 12.4789 15.6836 12.7178L10.6483 15.9548C10.22 16.2301 10.0059 16.3677 9.82883 16.3551C9.67452 16.3441 9.5326 16.2666 9.43989 16.1427C9.3335 16.0006 9.3335 15.7461 9.3335 15.237V8.76302Z" stroke="#6CB6E3" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
